import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActionType } from '../types/types'

export type Player = {
  status: ActionType
}

export interface UIState {
  isLoading: boolean
  isSnowing: boolean
}

const initialState: UIState = {
  isLoading: true,
  isSnowing: localStorage.getItem('snow') === 'on',
}

export const uiSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setLoding: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsSnowing: (state, action: PayloadAction<boolean>) => {
      state.isSnowing = action.payload
    },
  },
})
