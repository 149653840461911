import React from 'react'

interface Props {
  children: React.ReactNode
  name: string
  isSelected?: boolean // Enriched
}

export type TabElement<Props> = React.FC<Props>

export const Tab: React.FC<Props> = (props) => {
  if (!props.isSelected) return null

  return <div>{props.children}</div>
}
