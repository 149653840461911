import { achievementsList, NominationItem } from '../../../../data/nominations'
import { NominationType } from '../../../../store/profile.slice'
import style from './AchievementsList.module.scss'
import { NominationBlock } from './NominationBlock'
import cn from 'classnames'

interface Props {
  achievements: string[]
  smallMode?: boolean
}
type CollectorItem = {
  type: NominationType
  count: number
  item: NominationItem
}

export const AchievementsList = (props: Props) => {
  const collector: CollectorItem[] = []
  props.achievements.forEach((achievementType) => {
    const typeIndex = collector.findIndex((item) => item.type === achievementType)
    if (typeIndex !== -1) {
      collector[typeIndex].count += 1
    } else {
      const achObject = achievementsList.find((ach) => ach.type === achievementType)
      if (achObject) {
        collector.push({
          type: achievementType as NominationType,
          count: 1,
          item: achObject,
        })
      }
    }
  })

  return (
    <div className={cn(style.achievements, { [style.small]: props.smallMode })}>
      {collector.map((achievement, index) => {
        return <NominationBlock key={index} item={achievement.item} count={achievement.count} smallMode={props.smallMode} />
      })}
    </div>
  )
}
