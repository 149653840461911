import { useParams } from 'react-router-dom'
import { GrelkaState } from '../../store/store'
import { useOnload } from '../../hooks/useOnload'
import { ProfileState } from '../../store/profile.slice'
import { connect } from 'react-redux'
import { Texts } from '../texts/Texts'
import { Tabs } from '../ui/Tabs'
import { Tab } from '../ui/Tab'
import { Profile } from './user/Profile'
import { ActionType, Text, TextAdmin, UserAdmin } from '../../types/types'
import { GameState } from '../../store/game.slice'
import { VoteAdmin } from '../../api/user.api'
import { Game } from './user/Game'
import { Settings } from './user/Settings'
import { Rules } from './user/Rules'
import {
  getBetsMethod,
  getPlayersMethod,
  getProfileMethod,
  getResultsMethod,
  getTextsAdminMethod,
  getTextsMethod,
  getUsersAdminMethod,
  getVotesAdminMethod,
} from '../../methods/app.methods'
import { Votes } from './admin/Votes'
import { TextsAdmin } from './admin/TextsAdmin'
import { MyResults } from './user/Results'
import { ResultsState } from '../../store/results.slice'
import { Nominations } from './user/Nominations'
import { Bets } from './user/Bets'
import { HallOfFame } from './user/hallOfFame/HallOfFame'
import { Winners } from './user/Winners'
import { Guesses } from './user/Guess'
import { BetsState } from '../../store/bets.slice'
import { BetsResults } from './user/BetsResults'
import { Ranking } from './admin/Ranking'
import { CoinsAdmin } from './admin/CoinsAdmin'
import { MyClan } from './user/MyClan'
import { clanDecoder } from '../../data/statusDecoder'
import { UIState } from '../../store/ui.slice'
import { LoadingModal } from './user/Loading'

interface Props {
  texts: Text[]
  profile: ProfileState
  gameState: GameState
  votes: VoteAdmin[]
  users: UserAdmin[]
  textsAdmin: TextAdmin[]
  results: ResultsState
  bets: BetsState
  ui: UIState
}

export const UserPageComponent = (props: Props) => {
  let { guid = '' } = useParams()

  useOnload(() => {
    getProfileMethod(guid)
    getTextsMethod(guid)
    getPlayersMethod(guid)
    getResultsMethod(guid)
    getVotesAdminMethod(guid)
    getUsersAdminMethod(guid)
    getTextsAdminMethod(guid)
    getBetsMethod(guid)
  })

  if (!props.profile.name) {
    return null
  }

  const tabs = [
    { name: 'bio', title: 'Мой профиль' },
    { name: 'rules', title: 'Правила' },
    { name: 'texts', title: 'Тексты' },
  ]

  const IN_GAME = props.profile.status !== ActionType.GameEnded
  const GAME_ENDED = props.profile.status === ActionType.GameEnded

  if (IN_GAME) {
    tabs.push({ name: 'game', title: 'Игра' })
  }
  if (GAME_ENDED) {
    tabs.push({ name: 'winners', title: 'Победители' })
  }
  if (IN_GAME) {
    tabs.push({ name: 'nominations', title: 'Номинации' })
  }
  if (GAME_ENDED) {
    tabs.push({ name: 'results', title: 'Мои результаты' })
  }
  if (IN_GAME) {
    tabs.push({ name: 'bets', title: 'Ставки' })
  }
  if (GAME_ENDED) {
    tabs.push({ name: 'bets2', title: 'Ставки' })
  }
  if (IN_GAME) {
    tabs.push({ name: 'guess', title: 'Угадайка' })
  }
  tabs.push({ name: 'clan', title: 'Битва племён' })

  tabs.push({ name: 'settings', title: 'Настройки' })
  tabs.push({ name: 'halloffame', title: 'Зал славы' })

  if (props.votes.length > 0) {
    tabs.push({ name: 'votes', title: '★ Голоса' })
    tabs.push({ name: 'chart', title: '★ График' })
  }
  if (props.textsAdmin.length > 0) {
    tabs.push({ name: 'coins', title: '★ Коины' })
  }
  if (props.textsAdmin.length > 0) {
    tabs.push({ name: 'textsAdmin', title: '★ Админка' })
  }

  let myClan = ''
  if (props.profile.clan !== 0) {
    myClan = clanDecoder[props.profile.clan]
  }

  return (
    <div className="profile">
      <h2>Привет, {props.profile.name}!</h2>
      {props.profile.clan === 0 && <div className="clanInfo">Ты пока не состоишь в племени, ты одинокий странник...</div>}
      {props.profile.clan === 1 && <div className="clanInfo">Ты состоишь в племени «{myClan}», твои таинственные собратья гордятся тобой!</div>}
      {props.profile.clan === 2 && <div className="clanInfo">Ты состоишь в племени «{myClan}», твои пушистые собратья гордятся тобой!</div>}
      {props.profile.clan === 3 && <div className="clanInfo">Ты состоишь в племени «{myClan}», твои вкусные собратья гордятся тобой!</div>}
      {props.profile.clan === 4 && <div className="clanInfo">Ты состоишь в племени «{myClan}», твои бесюкатые собратья гордятся тобой!</div>}

      <Tabs tabs={tabs}>
        <Tab name="bio">
          <Profile texts={props.texts} profile={props.profile} />
        </Tab>
        <Tab name="rules">
          <Rules />
        </Tab>
        <Tab name="texts">
          <Texts texts={props.texts} profile={props.profile} />
        </Tab>
        <Tab name="game">
          <Game gameState={props.gameState} />
        </Tab>
        <Tab name="winners">
          <Winners gameState={props.gameState} />
        </Tab>
        <Tab name="nominations">
          <Nominations profile={props.profile} texts={props.texts} />
        </Tab>
        <Tab name="results">
          <MyResults results={props.results} />
        </Tab>
        <Tab name="bets">
          <Bets profile={props.profile} texts={props.texts} />
        </Tab>
        <Tab name="bets2">
          <BetsResults bets={props.bets} />
        </Tab>
        <Tab name="guess">
          <Guesses profile={props.profile} texts={props.texts} />
        </Tab>
        <Tab name="clan">
          <MyClan profile={props.profile} />
        </Tab>
        <Tab name="settings">
          <Settings profile={props.profile} />
        </Tab>
        <Tab name="halloffame">
          <HallOfFame />
        </Tab>
        <Tab name="votes">
          <Votes votes={props.votes} gameState={props.gameState} texts={props.texts} />
        </Tab>
        <Tab name="chart">
          <Ranking votes={props.votes} users={props.users} />
        </Tab>
        <Tab name="coins">
          <CoinsAdmin users={props.textsAdmin} />
        </Tab>
        <Tab name="textsAdmin">
          <TextsAdmin textsAdmin={props.textsAdmin} />
        </Tab>
      </Tabs>

      <LoadingModal isLoading={props.ui.isLoading} />
    </div>
  )
}

const mapStateToProps = (state: GrelkaState) => {
  return {
    profile: state.profile,
    texts: state.texts.list,
    gameState: state.game,
    votes: state.admin.votes,
    users: state.admin.users,
    textsAdmin: state.admin.textsAdmin,
    results: state.results,
    bets: state.bets,
    ui: state.ui,
  }
}

export const UserPage = connect(mapStateToProps, {})(UserPageComponent)
