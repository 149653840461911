import React from 'react'
import Snowflakes from 'magic-snowflakes'
import { connect } from 'react-redux'
import { GrelkaState } from '../../../../store/store'

interface Props {
  isSnowing: boolean
}

export const SnowManagerComponent = (props: Props) => {
  function clearSnow() {
    const showBodies = document.getElementsByClassName('snowflakes_body')
    if (showBodies.length > 0) {
      for (let index = 0; index <= showBodies.length; index++) {
        if (showBodies[index]) {
          showBodies[index].remove()
        }
      }
    }
  }

  React.useEffect(() => {
    if (!props.isSnowing) {
      clearSnow()
      setTimeout(() => {
        clearSnow()
      }, 100)
      setTimeout(() => {
        clearSnow()
      }, 500)
    } else {
      // eslint-disable-next-line
      const snowflakes = new Snowflakes({
        color: '#E5BBEB',
        count: 100,
      })
      // eslint-disable-next-line
      const snowflakes2 = new Snowflakes({
        color: '#5ECDEF',
        count: 100,
      })
    }
  }, [props.isSnowing])

  return null
}

const mapStateToProps = (state: GrelkaState) => {
  return {
    isSnowing: state.ui.isSnowing,
  }
}

export const SnowManager = connect(mapStateToProps, {})(SnowManagerComponent)
