import React from 'react'
import { date_voting_end } from '../../../data/gameConfig'
import { GameState } from '../../../store/game.slice'
import style from './Game.module.scss'
import { Tree } from './components/Tree'
import { localizeDate } from '../../../methods/date.methods'
import { AudioManager } from './components/AudioManager'

interface Props {
  gameState: GameState
}

export const Game = (props: Props) => {
  const realNow = new Date()
  const offset = realNow.getTimezoneOffset() + 180 // Сдвиг часового пояса + 180 минут (3 часа от гринвича)
  const now = new Date(realNow.getTime() + offset * 60 * 1000)
  const toDeadline = date_voting_end.valueOf() - now.valueOf()

  const [timer, setTimer] = React.useState(toDeadline)
  const [audioTick, set_audioTick] = React.useState(0)
  const interval = React.useRef<NodeJS.Timer>()

  React.useEffect(() => {
    function handleTimer() {
      interval.current = setInterval(() => {
        const realNow = new Date()
        const offset = realNow.getTimezoneOffset() + 180 // Сдвиг часового пояса + 180 минут (3 часа от гринвича)
        const now = new Date(realNow.getTime() + offset * 60 * 1000)

        const toDeadline = date_voting_end.valueOf() - now.valueOf()
        setTimer(toDeadline)
        set_audioTick(audioTick + 1)
      }, 1000)
    }

    if (timer === toDeadline) {
      handleTimer()
    }
  }, [timer, toDeadline, audioTick])

  const voted = props.gameState.votes.done
  const total = props.gameState.votes.total
  const votedPercent = (voted / total) * 100

  const extra_voted = props.gameState.extraVotes.done
  const extra_total = props.gameState.extraVotes.total
  const extra_votedPercent = (extra_voted / extra_total) * 100

  const nominations_voted = props.gameState.nominations.done
  const nominations_total = props.gameState.nominations.total
  const nominations_votedPercent = (nominations_voted / nominations_total) * 100

  // const bets_voted = props.gameState.bets.done;
  // const bets_total = props.gameState.bets.total;
  // const bets_votedPercent = (bets_voted / bets_total) * 100;

  const maxWidth = 600

  const hours = Math.floor(timer / 1000 / 60 / 60)
  const mins = Math.floor(timer / 1000 / 60) - hours * 60
  const secs = Math.floor(timer / 1000) - hours * 60 * 60 - mins * 60

  // const totalDone = props.gameState.votes.done + props.gameState.extraVotes.done + props.gameState.nominations.done + props.gameState.bets.done;
  // const totalTasks = props.gameState.votes.total + props.gameState.extraVotes.total + props.gameState.nominations.total + props.gameState.bets.total;
  const totalDone = props.gameState.votes.done + props.gameState.extraVotes.done + props.gameState.nominations.done
  const totalTasks = props.gameState.votes.total + props.gameState.extraVotes.total + props.gameState.nominations.total
  const percent = Math.ceil((totalDone / totalTasks) * 100)

  const voting_end = localizeDate(date_voting_end)

  return (
    <div className={style.game}>
      <AudioManager tick={audioTick} type="clock" />

      <div className={style.progress}>
        <h3>
          Проголосовало: {voted} из {total} игроков
        </h3>
        <div className={style.progressBar} style={{ maxWidth: maxWidth }}>
          <div className={style.filled} style={{ width: `${votedPercent}%` }}></div>
          <div className={style.await} style={{ width: `${100 - votedPercent}%` }}></div>
        </div>
      </div>

      <div className={style.progress}>
        <h3>
          Дополнительных оценок: {extra_voted} из {extra_total}
        </h3>
        <div className={style.progressBar} style={{ maxWidth: maxWidth }}>
          <div className={style.filled} style={{ width: `${extra_votedPercent}%` }}></div>
          <div className={style.await} style={{ width: `${100 - extra_votedPercent}%` }}></div>
        </div>
      </div>

      <div className={style.progress}>
        <h3>
          Номинаций: {nominations_voted} из {nominations_total}
        </h3>
        <div className={style.progressBar} style={{ maxWidth: maxWidth }}>
          <div className={style.filled} style={{ width: `${nominations_votedPercent}%` }}></div>
          <div className={style.await} style={{ width: `${100 - nominations_votedPercent}%` }}></div>
        </div>
      </div>

      {/* <div className={style.progress}>
        <h3>
          Ставок: {bets_voted} из {bets_total}
        </h3>
        <div className={style.progressBar} style={{ maxWidth: maxWidth }}>
          <div className={style.filled} style={{ width: `${bets_votedPercent}%` }}></div>
          <div className={style.await} style={{ width: `${100 - bets_votedPercent}%` }}></div>
        </div>
      </div> */}

      <Tree percent={percent} />

      <div className={style.percent}>
        <div className={style.title}>
          Выполнение <br /> ёлочного плана
        </div>
        <div className={style.number}>{percent}%</div>
        <div className={style.title2}>Нужно 75%, чтобы загорелась звезда!</div>
      </div>

      <div className={style.deadline}>
        <h3>Дедлайн голосования {voting_end} в 23:59:59</h3>
        {hours === 0 && (
          <div className={style.warning}>
            <div>☢️ И он очень близок ☢️</div>
          </div>
        )}
        {hours < 0 && (
          <div className={style.warning}>
            <div>💀 И он закончился 💀</div>
          </div>
        )}
        {hours > -1 && (
          <div className={style.timer}>
            <div className={style.number}>{hours}</div>
            <div>:</div>
            <div className={style.number}>{mins < 10 ? `0${mins}` : mins}</div>
            <div>:</div>
            <div className={style.number}>{secs < 10 ? `0${secs}` : secs}</div>
          </div>
        )}
      </div>
    </div>
  )
}
