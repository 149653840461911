import cn from 'classnames'
import { ActionType, ExtraVoteByType, TextAdmin } from '../../../types/types'
import { pluralSets, pluralText } from '../../../methods/text.methods'
import { ExtraVoteBlock } from '../user/components/ExtraVoteBlock'
import { NominationsList } from '../user/components/NominationsList'
import style from './TextsAdmin.module.scss'
import React from 'react'
import { statusDecoder } from '../../../data/statusDecoder'

interface Props {
  textsAdmin: TextAdmin[]
}

export const TextsAdmin = (props: Props) => {
  const textSortedByRating = [...props.textsAdmin]

  const [showNickname, set_showNickname] = React.useState(true)
  const [showRealname, set_showRealname] = React.useState(true)
  const [showTgid, set_showTgid] = React.useState(false)

  function compare(a: TextAdmin, b: TextAdmin) {
    const ratingA = a.rating
    const ratingB = b.rating
    const charactersA = a.characters
    const charactersB = b.characters

    if (ratingA === ratingB) {
      return charactersA < charactersB ? -1 : charactersA > charactersB ? 1 : 0
    } else {
      return ratingA > ratingB ? -1 : 1
    }

    // if (a.rating > b.rating) {
    //   return -1;
    // }
    // if (a.rating < b.rating) {
    //   return 1;
    // }
    // return 0;
  }

  textSortedByRating.sort(compare)

  function summCriteria(criteria: ExtraVoteByType) {
    return criteria.bad + criteria.neutral * 2 + criteria.good * 3
  }

  const textsSortedBy_like = [...props.textsAdmin]
  textsSortedBy_like.sort(function (textA, textB) {
    const fullCriteriaA = summCriteria(textA.extraVotes.like)
    const fullCriteriaB = summCriteria(textB.extraVotes.like)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })

  const textsSortedBy_theme = [...props.textsAdmin]
  textsSortedBy_theme.sort(function (textA, textB) {
    const fullCriteriaA = summCriteria(textA.extraVotes.theme)
    const fullCriteriaB = summCriteria(textB.extraVotes.theme)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })

  const textsSortedBy_readable = [...props.textsAdmin]
  textsSortedBy_readable.sort(function (textA, textB) {
    const fullCriteriaA = summCriteria(textA.extraVotes.readable)
    const fullCriteriaB = summCriteria(textB.extraVotes.readable)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })

  const textsSortedBy_style = [...props.textsAdmin]
  textsSortedBy_style.sort(function (textA, textB) {
    const fullCriteriaA = summCriteria(textA.extraVotes.style)
    const fullCriteriaB = summCriteria(textB.extraVotes.style)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })

  function toggle_showNickname() {
    set_showNickname(!showNickname)
  }
  function toggle_showRealname() {
    set_showRealname(!showRealname)
  }
  function toggle_showTgid() {
    set_showTgid(!showTgid)
  }

  return (
    <div className={style.textsAdmin}>
      <div className={style.topsTexts}>
        <h2>Краткий рейтинг</h2>

        <div className={style.cheat}>угадал | был угадан | like-theme-readable-style | номинации | пчелокоины | арбузокоины | рецензии</div>
        <div className={style.cheat}>
          <label htmlFor="nickname">Никнейм:</label>
          <input type="checkbox" id="nickname" checked={showNickname} onChange={toggle_showNickname} /> | <label htmlFor="realname">Реальное имя:</label>
          <input type="checkbox" id="realname" checked={showRealname} onChange={toggle_showRealname} /> | <label htmlFor="tgid">TG id:</label>
          <input type="checkbox" id="tgid" checked={showTgid} onChange={toggle_showTgid} />
        </div>

        {textSortedByRating.map((text, index) => {
          const beeCoins = text.bets.filter((bet) => bet.coin === 'beecoin').reduce((acc, bet) => acc + bet.amount, 0)
          const watermelonCoins = text.bets.filter((bet) => bet.coin === 'watermeloncoin').reduce((acc, bet) => acc + bet.amount, 0)
          const extra_like = summCriteria(text.extraVotes.like)
          const extra_theme = summCriteria(text.extraVotes.theme)
          const extra_readable = summCriteria(text.extraVotes.readable)
          const extra_style = summCriteria(text.extraVotes.style)
          const guessPercent = text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0
          const guessFromPercent = text.guessesFrom.total > 0 ? Math.ceil((text.guessesFrom.correct / text.guessesFrom.total) * 100) : 0

          return (
            <div
              key={index}
              className={cn(style.topLine, {
                [style.mine]: text.isMine,
              })}
            >
              <div>
                {text.status === ActionType.AwaitEndVoting && '✅'}
                {text.status === ActionType.AwaitVoting && '⚠️'}#{index + 1}
              </div>
              <div>{pluralText(text.rating, pluralSets.balls)}</div>
              <div>{text.characters}</div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  «{text.title}»
                </a>
              </div>
              <div>
                {showNickname && <span>{text.user}</span>}
                {showRealname && <span> | {text.realUser}</span>}
                {showTgid && <span> | {text.user_id}</span>}
              </div>
              <div>
                {guessFromPercent}% ({text.guessesFrom.correct}/{text.guessesFrom.total}) | {guessPercent}% ({text.guesses.correct}/{text.guesses.total}) |{' '}
                {extra_like}-{extra_theme}-{extra_readable}-{extra_style} | ⭐️
                {text.nominations.length} | 🐝{beeCoins} | 🍉{watermelonCoins} | ✉️
                {text.reviewsTexts.length}
              </div>
            </div>
          )
        })}
      </div>

      {props.textsAdmin.map((text, index) => {
        const ratingNumber = textSortedByRating.findIndex((text2) => text2.user_id === text.user_id) + 1

        const beeCoins = text.bets.filter((bet) => bet.coin === 'beecoin').reduce((acc, bet) => acc + bet.amount, 0)
        const watermelonCoins = text.bets.filter((bet) => bet.coin === 'watermeloncoin').reduce((acc, bet) => acc + bet.amount, 0)

        const guessPercent = text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0
        const guessFromPercent = text.guessesFrom.total > 0 ? Math.ceil((text.guessesFrom.correct / text.guessesFrom.total) * 100) : 0

        const place_like = textsSortedBy_like.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_theme = textsSortedBy_theme.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_readable = textsSortedBy_readable.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_style = textsSortedBy_style.findIndex((_text) => _text.user_id === text.user_id) + 1

        return (
          <div
            className={cn(style.textBlock, {
              [style.gold]: ratingNumber === 1,
              [style.silver]: ratingNumber === 2,
              [style.bronze]: ratingNumber === 3,
            })}
            key={index}
          >
            <div className={style.texttitle}>
              <span>
                #{index + 1}: «{text.title}»
              </span>
            </div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                Ссылка на текст
              </a>
            </div>
            <div className={style.textStats}>
              <div>
                Автор: {text.user} ({text.user_id})
              </div>
              <div>Реальный автор: {text.realUser}</div>
              <div>Статус: {statusDecoder[text.status]}</div>
              <div>
                Голоса: {text.done.votes} | Оценки: {text.done.extraVotes} | Номинации: {text.done.nominations} | Ставки: {text.done.bets} | Рецензии:{' '}
                {text.done.reviews}
              </div>
              <div>
                Угадали: {text.guesses.correct} из {text.guesses.total} ({guessPercent}%)
              </div>
              <div>
                Угадал других: {text.guessesFrom.correct} из {text.guessesFrom.total} ({guessFromPercent}%)
              </div>

              <hr />

              <div className={style.statsGrid4}>
                <div className={style.statsCell}>
                  <div className={style.statTitle}>Голосов</div>
                  <div className={style.statValue}>{text.rating}</div>
                </div>
                <div className={style.statsCell}>
                  <div className={style.statTitle}>Место</div>
                  <div className={style.statValue}>#{ratingNumber}</div>
                </div>
                <div className={style.statsCell}>
                  <div className={style.statTitle}>🐝</div>
                  <div className={style.statValue}>{beeCoins}</div>
                </div>
                <div className={style.statsCell}>
                  <div className={style.statTitle}>🍉</div>
                  <div className={style.statValue}>{watermelonCoins}</div>
                </div>
              </div>
              <hr />
              <div className={style.statsGrid4}>
                <ExtraVoteBlock type="like" extraVotes={text.extraVotes} place={place_like} />
                <ExtraVoteBlock type="theme" extraVotes={text.extraVotes} place={place_theme} />
                <ExtraVoteBlock type="readable" extraVotes={text.extraVotes} place={place_readable} />
                <ExtraVoteBlock type="style" extraVotes={text.extraVotes} place={place_style} />
              </div>
              <NominationsList nominations={text.nominations} nominationsWon={text.nominationsWon} />
              <div className={style.reviews}>
                {text.reviewsTexts.map((review, index) => {
                  return (
                    <div key={index} className={style.review}>
                      от {review.userName} ({review.userRealName}): {review.text}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}

      <hr />

      <div className={style.tops}>
        <h3>Общее впечатление:</h3>
        {textsSortedBy_like.map((text, index) => {
          const total = summCriteria(text.extraVotes.like)
          return (
            <div
              key={index}
              className={cn(style.topLine, {
                [style.mine]: text.isMine,
              })}
            >
              <div>#{index + 1}</div>
              <div>{pluralText(total, pluralSets.balls)}</div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  «{text.title}»
                </a>
              </div>
              <div>
                {text.user} aka {text.realUser} ({text.user_id})
              </div>
            </div>
          )
        })}

        <h3>Соответствие теме:</h3>
        {textsSortedBy_theme.map((text, index) => {
          const total = summCriteria(text.extraVotes.theme)
          return (
            <div
              key={index}
              className={cn(style.topLine, {
                [style.mine]: text.isMine,
              })}
            >
              <div>#{index + 1}</div>
              <div>{pluralText(total, pluralSets.balls)}</div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  «{text.title}»
                </a>
              </div>
              <div>
                {text.user} aka {text.realUser} ({text.user_id})
              </div>
            </div>
          )
        })}

        <h3>Читабельность:</h3>
        {textsSortedBy_readable.map((text, index) => {
          const total = summCriteria(text.extraVotes.readable)
          return (
            <div
              key={index}
              className={cn(style.topLine, {
                [style.mine]: text.isMine,
              })}
            >
              <div>#{index + 1}</div>
              <div>{pluralText(total, pluralSets.balls)}</div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  «{text.title}»
                </a>
              </div>
              <div>
                {text.user} aka {text.realUser} ({text.user_id})
              </div>
            </div>
          )
        })}

        <h3>Красота текста:</h3>
        {textsSortedBy_style.map((text, index) => {
          const total = summCriteria(text.extraVotes.style)
          return (
            <div
              key={index}
              className={cn(style.topLine, {
                [style.mine]: text.isMine,
              })}
            >
              <div>#{index + 1}</div>
              <div>{pluralText(total, pluralSets.balls)}</div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  «{text.title}»
                </a>
              </div>
              <div>
                {text.user} aka {text.realUser} ({text.user_id})
              </div>
            </div>
          )
        })}
      </div>

      <div className={style.totals}>
        <div className={style.totalBlock}>
          <h3>Братская могила</h3>
          <div className={style.icon}>💀</div>
          <div className={style.count}>{props.textsAdmin.filter((text) => text.rating === 0).length}</div>
        </div>
        <div className={style.totalBlock}>
          <h3>Рецензий</h3>
          <div className={style.icon}>✉️</div>
          <div className={style.count}>{props.textsAdmin.reduce((acc, text) => acc + text.reviewsTexts.length, 0)}</div>
        </div>

        <div className={style.totalBlock}>
          <h3>Угадано текстов</h3>
          <div className={style.icon}>🧠</div>
          {(() => {
            const percent =
              props.textsAdmin.reduce((acc, text) => {
                if (text.guesses.total > 0) {
                  return acc + (text.guesses.correct / text.guesses.total) * 100
                } else {
                  return acc + 0
                }
              }, 0) / props.textsAdmin.length
            return <div className={style.count}>{Math.ceil(percent)}%</div>
          })()}
        </div>

        <div className={style.totalBlock}>
          <h3>IQ грелочки</h3>
          <div className={style.icon}>🧠</div>
          {(() => {
            const percent =
              props.textsAdmin.reduce((acc, text) => {
                if (text.guessesFrom.total > 0) {
                  return acc + (text.guessesFrom.correct / text.guessesFrom.total) * 100
                } else {
                  return acc + 0
                }
              }, 0) / props.textsAdmin.length
            return <div className={style.count}>{Math.ceil(percent)}%</div>
          })()}
        </div>
      </div>
    </div>
  )
}
