import React, { useRef } from 'react'

type SoundType = 'clock' | 'cash' | 'chpok' | 'icq' | 'arigato' | 'sword' | 'pulse'

interface Props {
  tick: number
  type: SoundType
}

export const AudioManager = (props: Props) => {
  const clock_audioRef = useRef<HTMLAudioElement>(null)
  const cash_audioRef = useRef<HTMLAudioElement>(null)
  const chpok_audioRef = useRef<HTMLAudioElement>(null)
  const icq_audioRef = useRef<HTMLAudioElement>(null)
  const arigato_audioRef = useRef<HTMLAudioElement>(null)
  const sword_audioRef = useRef<HTMLAudioElement>(null)
  const pulse_audioRef = useRef<HTMLAudioElement>(null)

  function checkSoundStorageConfig() {
    return localStorage.getItem('sound') === 'on'
  }

  React.useEffect(() => {
    if (props.tick === 0) {
      return
    }
    if (!checkSoundStorageConfig()) {
      return
    }
    if (props.type === 'clock' && clock_audioRef.current) {
      clock_audioRef.current.currentTime = 0
      clock_audioRef.current.play()
    }
    if (props.type === 'cash' && cash_audioRef.current) {
      cash_audioRef.current.currentTime = 0
      cash_audioRef.current.play()
    }
    if (props.type === 'chpok' && chpok_audioRef.current) {
      chpok_audioRef.current.currentTime = 0
      chpok_audioRef.current.play()
    }
    if (props.type === 'icq' && icq_audioRef.current) {
      icq_audioRef.current.currentTime = 0
      icq_audioRef.current.play()
    }
    if (props.type === 'arigato' && arigato_audioRef.current) {
      arigato_audioRef.current.currentTime = 0
      arigato_audioRef.current.play()
    }
    if (props.type === 'sword' && sword_audioRef.current) {
      sword_audioRef.current.currentTime = 0
      sword_audioRef.current.play()
    }
    if (props.type === 'pulse' && pulse_audioRef.current) {
      pulse_audioRef.current.currentTime = 0
      pulse_audioRef.current.play()
    }
  }, [props.tick, props.type])

  React.useEffect(() => {
    if (clock_audioRef.current) {
      clock_audioRef.current.pause()
      clock_audioRef.current.currentTime = 0
    }
    if (cash_audioRef.current) {
      cash_audioRef.current.pause()
      cash_audioRef.current.currentTime = 0
    }
    if (chpok_audioRef.current) {
      chpok_audioRef.current.pause()
      chpok_audioRef.current.currentTime = 0
    }
    if (icq_audioRef.current) {
      icq_audioRef.current.pause()
      icq_audioRef.current.currentTime = 0
    }
    if (arigato_audioRef.current) {
      arigato_audioRef.current.pause()
      arigato_audioRef.current.currentTime = 0
    }
    if (sword_audioRef.current) {
      sword_audioRef.current.pause()
      sword_audioRef.current.currentTime = 0
    }
    if (pulse_audioRef.current) {
      pulse_audioRef.current.pause()
      pulse_audioRef.current.currentTime = 0
    }
  }, [])

  return (
    <>
      <audio ref={clock_audioRef} src="/sound/clock.mp3" autoPlay={false} />
      <audio ref={cash_audioRef} src="/sound/cash.mp3" autoPlay={false} />
      <audio ref={chpok_audioRef} src="/sound/chpok.mp3" autoPlay={false} />
      <audio ref={icq_audioRef} src="/sound/icq.mp3" autoPlay={false} />
      <audio ref={arigato_audioRef} src="/sound/arigato.mp3" autoPlay={false} />
      <audio ref={sword_audioRef} src="/sound/sword.mp3" autoPlay={false} />
      <audio ref={pulse_audioRef} src="/sound/pulse.mp3" autoPlay={false} />
    </>
  )
}
