import { useParams } from 'react-router-dom'
import { postChangeBalance, postCreateBalance } from '../../../api/user.api'
import { Coin, TextAdmin } from '../../../types/types'
import style from './CoinsAdmin.module.scss'
import { getTextsAdminMethod } from '../../../methods/app.methods'
import React from 'react'

interface Props {
  users: TextAdmin[]
}

export const CoinsAdmin = (props: Props) => {
  let { guid = '' } = useParams()
  const [showRealname, set_showRealname] = React.useState(false)

  function changeBalance(userId: number, coin: Coin, amount: number) {
    const body = {
      guid,
      userId,
      amount,
      coin,
    }
    postChangeBalance(body).then(() => {
      getTextsAdminMethod(guid)
    })
  }

  function createBalance(userId: number) {
    const body = {
      guid,
      userId,
    }
    postCreateBalance(body).then(() => {
      getTextsAdminMethod(guid)
    })
  }

  function toggle_showRealname() {
    set_showRealname(!showRealname)
  }

  return (
    <>
      <div className={style.coinsAdmin}>
        <div className={style.checkboxes}>
          <input type="checkbox" id="realname" checked={showRealname} onChange={toggle_showRealname} />
          <label htmlFor="realname">Показать имена</label>
        </div>

        <table>
          <thead>
            <tr>
              <td>id</td>
              <td>Имя</td>
              <td>Реальное имя</td>
              <td>Может?</td>
              <td>Пчелокоины</td>
              <td>Арбузокоины</td>
              <td>Действия</td>
            </tr>
          </thead>
          <tbody>
            {props.users.map((user, index) => {
              const canPay = user.balance.canPay
              return (
                <tr key={index}>
                  <td>{user.user_id}</td>
                  <td>{user.user}</td>
                  <td>{showRealname ? user.realUser : '❄❄❄'}</td>
                  <td>{user.balance.canPay ? '✅' : '❌'}</td>
                  <td>{canPay ? user.balance.beecoins : 'n/a'}</td>
                  <td>{canPay ? user.balance.watermeloncoins : 'n/a'}</td>
                  <td>
                    {canPay && (
                      <>
                        <button onClick={() => changeBalance(user.user_id, 'beecoin', 1)}>+1 🐝</button>
                        <button onClick={() => changeBalance(user.user_id, 'watermeloncoin', 1)}>+1 🍉</button>
                        <button onClick={() => changeBalance(user.user_id, 'beecoin', -1)}>-1 🐝</button>
                        <button onClick={() => changeBalance(user.user_id, 'watermeloncoin', -1)}>-1 🍉</button>
                      </>
                    )}
                    {!canPay && (
                      <>
                        <button onClick={() => createBalance(user.user_id)}>Создать баланс</button>
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
