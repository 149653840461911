import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Coin, ExtraVoteByType } from '../types/types'

export type Rate = 'good' | 'bad' | 'neutral'

export type ExtraVoteValue = 1 | 2 | 3
export type ExtraVoteType = 'theme' | 'like' | 'readable' | 'style' | 'choose'

export interface ExtraVote {
  type: ExtraVoteType
  value: ExtraVoteValue
  textIndex: number
}

export interface ResultsState {
  title: string
  characters: number
  textsShorterMine: number
  textsLongerMine: number
  rating: number
  myPlace: number
  votesTotal: number
  extraVotesTotal: number
  extraVotes: {
    [key: string]: ExtraVoteByType
  }
  nominations: string[]
  nominationsWon: string[]
  reviews: { text: string }[]
  betsFromMe: {
    amount: number
    coin: Coin
    textTitle: string
    factor: number
  }[]
  betsToMe: {
    beecoin: number
    watermeloncoin: number
  }
  extraPlace: {
    like: number
    theme: number
    readable: number
    style: number
  }
  guesses: {
    correct: number
    total: number
  }
  guessesFrom: {
    correct: number
    total: number
  }
}

const initialState: ResultsState = {
  title: '',
  characters: 0,
  textsShorterMine: 0,
  textsLongerMine: 0,
  rating: 0,
  myPlace: 0,
  votesTotal: 0,
  extraVotesTotal: 0,
  extraVotes: {
    like: {
      bad: 0,
      neutral: 1,
      good: 3,
    },
    theme: {
      bad: 1,
      neutral: 1,
      good: 3,
    },
    readable: {
      bad: 0,
      neutral: 1,
      good: 4,
    },
    style: {
      bad: 0,
      neutral: 2,
      good: 3,
    },
  },
  nominations: [],
  nominationsWon: [],
  reviews: [],
  betsFromMe: [],
  betsToMe: {
    beecoin: 0,
    watermeloncoin: 0,
  },
  extraPlace: {
    like: 0,
    theme: 0,
    readable: 0,
    style: 0,
  },
  guesses: {
    correct: 0,
    total: 0,
  },
  guessesFrom: {
    correct: 0,
    total: 0,
  },
}

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<ResultsState>) => {
      state.title = action.payload.title
      state.characters = action.payload.characters
      state.textsShorterMine = action.payload.textsShorterMine
      state.textsLongerMine = action.payload.textsLongerMine
      state.rating = action.payload.rating
      state.myPlace = action.payload.myPlace
      state.votesTotal = action.payload.votesTotal
      state.extraVotesTotal = action.payload.extraVotesTotal
      state.extraVotes = action.payload.extraVotes
      state.nominations = action.payload.nominations
      state.nominationsWon = action.payload.nominationsWon
      state.reviews = action.payload.reviews
      state.betsFromMe = action.payload.betsFromMe
      state.betsToMe = action.payload.betsToMe
      state.extraPlace = action.payload.extraPlace
      state.guesses = action.payload.guesses
      state.guessesFrom = action.payload.guessesFrom
    },
  },
})
