import React from 'react'
import style from './Settings.module.scss'
import { ProfileState } from '../../../store/profile.slice'
import { grelkaStore } from '../../../store/store'
import { uiSlice } from '../../../store/ui.slice'
import cn from 'classnames'

interface Props {
  profile: ProfileState
}

export const Settings = (props: Props) => {
  var storedTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  var storedScreenSize = localStorage.getItem('screen') || 'medium'
  var storedSoundConfig = localStorage.getItem('sound') || 'on'
  var storedSnowConfig = localStorage.getItem('snow') || 'on'

  const [currentTheme, set_currentTheme] = React.useState(storedTheme)
  const [currentScreenSize, set_currentScreenSize] = React.useState(storedScreenSize)
  const [currentSound, set_currentSound] = React.useState(storedSoundConfig)
  const [currentSnow, set_currentSnow] = React.useState(storedSnowConfig)

  function toggleTheme(theme: string) {
    document.documentElement.setAttribute('data-theme', theme)
    localStorage.setItem('theme', theme)
    set_currentTheme(theme)
  }

  function toggleScreenSize() {
    let newSize = ''
    if (currentScreenSize === 'thin') {
      newSize = 'medium'
    }
    if (currentScreenSize === 'medium') {
      newSize = 'wide'
    }
    if (currentScreenSize === 'wide') {
      newSize = 'thin'
    }
    document.documentElement.setAttribute('data-screen', newSize)
    localStorage.setItem('screen', newSize)
    set_currentScreenSize(newSize)
  }

  function toggleSound() {
    let newValue = ''
    if (currentSound === 'on') {
      newValue = 'off'
    }
    if (currentSound === 'off') {
      newValue = 'on'
    }
    localStorage.setItem('sound', newValue)
    set_currentSound(newValue)
  }

  function toggleSnow() {
    let newValue = ''
    if (currentSnow === 'on') {
      newValue = 'off'
      grelkaStore.dispatch(uiSlice.actions.setIsSnowing(false))
    }
    if (currentSnow === 'off') {
      newValue = 'on'
      grelkaStore.dispatch(uiSlice.actions.setIsSnowing(true))
    }
    localStorage.setItem('snow', newValue)
    set_currentSnow(newValue)
  }

  const ThemeButton = ({ theme, title }: { theme: string; title: string }) => {
    return (
      <div className={style.buttonWrapper}>
        <button className={style[theme]} onClick={() => toggleTheme(theme)}>
          <img src={`/img/settings/${theme}.png`} alt="config theme button" />
          <div>{title}</div>
        </button>
        <div className={style.text}>{currentTheme === theme && 'Выбрано'} </div>
      </div>
    )
  }

  return (
    <>
      <div className={style.settings}>
        <div className={style.settingsBlock}>
          <h3>Тема</h3>
          <div className={style.buttons}>
            <ThemeButton theme="light" title="светлая" />
            <ThemeButton theme="dark" title="тёмная" />
            <ThemeButton theme="bee" title="пчола" />
            <ThemeButton theme="watermelon" title="арбуз" />
            <ThemeButton theme="pushki" title="пушки" />
          </div>
        </div>

        {currentTheme === 'pushki' && (
          <div className={style.pushkiLink}>
            <a href="/img/pushki.jpg" target="_blank">
              <img src="/img/pushki.jpg" alt="" />
            </a>
            <a href="/img/pushki.jpg" target="_blank">
              <div className={style.button}>посмотреть пушков</div>
            </a>
          </div>
        )}

        <div className={style.iconSettingsBlock}>
          <div className={cn(style.iconButton, { [style.hidden]: currentScreenSize !== 'thin' })} onClick={toggleScreenSize}>
            <img src="/img/settings/size_thin.png" alt="config button" />
            <div>Экран узкий</div>
          </div>
          <div className={cn(style.iconButton, { [style.hidden]: currentScreenSize !== 'medium' })} onClick={toggleScreenSize}>
            <img src="/img/settings/size_medium.png" alt="config button" />
            <div>Экран обычный</div>
          </div>
          <div className={cn(style.iconButton, { [style.hidden]: currentScreenSize !== 'wide' })} onClick={toggleScreenSize}>
            <img src="/img/settings/size_wide.png" alt="config button" />
            <div>Экран широкий</div>
          </div>

          <div className={cn(style.iconButton, { [style.hidden]: currentSound !== 'on' })} onClick={toggleSound}>
            <img src="/img/settings/sound_on.png" alt="config button" />
            <div>со звуками</div>
          </div>
          <div className={cn(style.iconButton, { [style.hidden]: currentSound !== 'off' })} onClick={toggleSound}>
            <img src="/img/settings/sound_off.png" alt="config button" />
            <div>без звуков</div>
          </div>

          <div className={cn(style.iconButton, { [style.hidden]: currentSnow !== 'on' })} onClick={toggleSnow}>
            <img src="/img/settings/snow_on.png" alt="config button" />
            <div>со снегом</div>
          </div>
          <div className={cn(style.iconButton, { [style.hidden]: currentSnow !== 'off' })} onClick={toggleSnow}>
            <img src="/img/settings/snow_off.png" alt="config button" />
            <div>без снега</div>
          </div>
        </div>

        <div className={style.footer}>
          <img src="/img/iyra.png" alt="" />
          <div className={style.text}>
            Вжух!
            <br />
            Заходи{' '}
            <a href="https://shining-project.ru/" target="_blank" rel="noreferrer">
              на огонёк
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
