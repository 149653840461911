import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActionType, TextAdmin, UserAdmin } from '../types/types'
import { VoteAdmin } from '../api/user.api'

export type Player = {
  status: ActionType
}

export interface AdminState {
  votes: VoteAdmin[]
  users: UserAdmin[]
  textsAdmin: TextAdmin[]
}

const initialState: AdminState = {
  votes: [],
  users: [],
  textsAdmin: [],
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setVotes: (state, action: PayloadAction<AdminState['votes']>) => {
      state.votes = action.payload
    },
    setUsers: (state, action: PayloadAction<AdminState['users']>) => {
      state.users = action.payload
    },
    setTextsAdmin: (state, action: PayloadAction<AdminState['textsAdmin']>) => {
      state.textsAdmin = action.payload
    },
  },
})
