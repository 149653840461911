import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActionType, Coin, Guess } from '../types/types'
import { ClanType } from '../api/user.api'

export type Rate = 'good' | 'bad' | 'neutral'

export type ExtraVoteValue = 1 | 2 | 3
export type ExtraVoteType = 'theme' | 'like' | 'readable' | 'style' | 'choose'
export type NominationType =
  | 'standup'
  | 'drama'
  | 'nabokov'
  | 'mimimi'
  | 'chef'
  | 'ogo'
  | 'not_ready'
  | 'normie'
  | 'light'
  | 'dark'
  | 'hot'
  | 'cold'
  | 'scary'
  | 'cosy'
  | 'hard'
  | 'lore'
  | 'less_dialogs'
  | 'many_dialogs'
  | 'educational'
  | 'massacre'
  | 'horny'
  // -----
  | 'literally'
  | 'interesting'
  | '1st'
  | '2nd'
  | '3rd'
  | 'dead'
  | 'veteran'
  | 'ban'
  | 'pass'
  | 'oversize'
  | 'longest'
  | 'shortest'
  | 'lowhp'
  | 'illustrator'
  | 'fluffy'
  | 'potato'
  | 'betwin'
  | 'betlose'
  | 'nominant10'
  | 'nominant20'
  | 'dominant'
  | 'seasonwinner'
  | 'other'
  | 'like'
  | 'theme'
  | 'readable'
  | 'style'
  | 'graphoman'
  | 'no_nominations'
  | '1season'
  | '2season'
  | 'clan'

export interface ExtraVote {
  type: ExtraVoteType
  value: ExtraVoteValue
  textIndex: number
}
export interface Nomination {
  textIndex: number
  type: NominationType
}

export interface ProfileState {
  name: string
  realName: string
  status: ActionType
  clan: ClanType
  myClan: {
    name: string
    power: number
    // status: ActionType;
    // extraVotes: number;
    // nominations: number;
    // bets: number;
    // guesses: number;
  }[]
  anotherClans: {
    clan: ClanType
    users: {
      name: string
      power: number
    }[]
    // power: number;
    // count: number;
  }[]
  votes: {
    link: string
    value: number
  }[]
  extraVotes: ExtraVote[]
  nominations: Nomination[]
  balance: {
    beecoins: number
    watermeloncoins: number
  }
  bets: {
    amount: number
    coin: Coin
    textIndex: number
  }[]
  guesses: Guess[]
}

const initialState: ProfileState = {
  name: '',
  realName: '',
  status: ActionType.Greetings,
  clan: 0,
  myClan: [],
  anotherClans: [],
  votes: [],
  extraVotes: [],
  nominations: [],
  balance: {
    beecoins: 0,
    watermeloncoins: 0,
  },
  bets: [],
  guesses: [],
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.name = action.payload.name
      state.realName = action.payload.realName
      state.status = action.payload.status
      state.clan = action.payload.clan
      state.myClan = action.payload.myClan
      state.anotherClans = action.payload.anotherClans
      state.votes = action.payload.votes
      state.extraVotes = action.payload.extraVotes
      state.nominations = action.payload.nominations
      state.balance = action.payload.balance
      state.bets = action.payload.bets
      state.guesses = action.payload.guesses
    },
    addExtraVote: (state, action: PayloadAction<ExtraVote>) => {
      state.extraVotes = [...state.extraVotes, action.payload]
    },
  },
})
