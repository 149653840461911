import { fameUsers, SKIP_TEXT } from '../../../../data/users'
import { AchievementsList } from '../components/AchievementsList'
import { NominationsList } from '../components/NominationsList'
import style from './HallOfFame.module.scss'
import cn from 'classnames'
import { TextRow } from './HallOfFame.textRow'

export const HallOfFameSeason1 = () => {
  const fameUsersSorted = [...fameUsers]
  fameUsersSorted.sort(function (a, b) {
    const summA = a.round1[1] + a.round2[1] + a.round3[1] + a.round4[1] + a.round5[1]
    const summB = b.round1[1] + b.round2[1] + b.round3[1] + b.round4[1] + b.round5[1]
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  const fameUserSortedByRound1 = [...fameUsers]
  fameUserSortedByRound1.sort(function (a, b) {
    const valueA = a.round1[1]
    const valueB = b.round1[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  const fameUserSortedByRound2 = [...fameUsers]
  fameUserSortedByRound2.sort(function (a, b) {
    const valueA = a.round2[1]
    const valueB = b.round2[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  const fameUserSortedByRound3 = [...fameUsers]
  fameUserSortedByRound3.sort(function (a, b) {
    const valueA = a.round3[1]
    const valueB = b.round3[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  const fameUserSortedByRound4 = [...fameUsers]
  fameUserSortedByRound4.sort(function (a, b) {
    const valueA = a.round4[1]
    const valueB = b.round4[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  const fameUserSortedByRound5 = [...fameUsers]
  fameUserSortedByRound5.sort(function (a, b) {
    const valueA = a.round5[1]
    const valueB = b.round5[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  return (
    <>
      {fameUsersSorted.map((user, index) => {
        const anyBan = user.round3[1] === -1
        const summ = user.round1[1] + user.round2[1] + user.round3[1] + user.round4[1] + user.round5[1] + (anyBan ? 1 : 0)

        const isVeteran =
          user.round1[0] !== SKIP_TEXT &&
          user.round2[0] !== SKIP_TEXT &&
          user.round3[0] !== SKIP_TEXT &&
          user.round4[0] !== SKIP_TEXT &&
          user.round5[0] !== SKIP_TEXT

        const round1place = fameUserSortedByRound1.findIndex((_user) => _user.name === user.name)
        const round2place = fameUserSortedByRound2.findIndex((_user) => _user.name === user.name)
        const round3place = fameUserSortedByRound3.findIndex((_user) => _user.name === user.name)
        const round4place = fameUserSortedByRound4.findIndex((_user) => _user.name === user.name)
        const round5place = fameUserSortedByRound5.findIndex((_user) => _user.name === user.name)
        return (
          <div className={style.userRow} key={index}>
            <h3>
              #{index + 1}: {user.name}
            </h3>
            <div className={style.userGrid}>
              <div className={style.gridCell}>
                {isVeteran && <div className={cn(style.badge, style.purple)}>Ветеран всех грелочек!</div>}
                <br />
                <br />

                <TextRow index={1} cellData={user.round1} place={round1place} />
                <TextRow index={2} cellData={user.round2} place={round2place} />
                <TextRow index={3} cellData={user.round3} place={round3place} />
                <TextRow index={4} cellData={user.round4} place={round4place} />
                <TextRow index={5} cellData={user.round5} place={round5place} />
                <br />

                {user.achievements.length > 0 && (
                  <>
                    <h3>Достижения:</h3>
                    <AchievementsList achievements={user.achievements} smallMode />
                  </>
                )}

                {user.nominations.length > 0 && (
                  <>
                    <h3>Выигранные номинации:</h3>
                    <NominationsList nominations={user.nominations} nominationsWon={[]} smallMode />
                  </>
                )}
              </div>
              <div className={cn(style.gridCell, style.balls)}>
                <div>Всего баллов</div>
                <div className={style.ballsCount}>{summ}</div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
