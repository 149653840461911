import style from './Tree.module.scss'
import cn from 'classnames'

interface OrbProps {
  percentToLight: number
  percent: number
  nn: string
}

const Orb = (props: OrbProps) => {
  return (
    <div className={cn(style.orb, style[`orb_${props.nn}`])}>
      <div
        className={cn(style.orbSphere, {
          [style.on]: props.percent >= props.percentToLight,
        })}
      ></div>
    </div>
  )
}

interface Props {
  percent: number
}

export const Tree = (props: Props) => {
  const rubikons = [5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60, 65, 70, 75]

  return (
    <div className={style.tree}>
      <div className={style.stvolWrapper}>
        <div className={cn(style.stvol)}></div>
      </div>
      <div className={cn(style.layer, style.layer_bot_wrapper)}>
        <div className={style.layer_bot}></div>
      </div>
      <div className={cn(style.layer, style.layer_mid_wrapper)}>
        <div className={style.layer_mid}></div>
      </div>
      <div className={cn(style.layer, style.layer_top_wrapper)}>
        <div className={style.layer_top}></div>
      </div>

      <div className={cn(style.star, { [style.on]: props.percent > rubikons[13] })}></div>

      <Orb nn="1_1" percent={props.percent} percentToLight={rubikons[0]} />
      <Orb nn="1_2" percent={props.percent} percentToLight={rubikons[1]} />
      <Orb nn="1_3" percent={props.percent} percentToLight={rubikons[2]} />
      <Orb nn="1_4" percent={props.percent} percentToLight={rubikons[3]} />
      <Orb nn="1_5" percent={props.percent} percentToLight={rubikons[4]} />
      <Orb nn="1_6" percent={props.percent} percentToLight={rubikons[5]} />

      <Orb nn="2_1" percent={props.percent} percentToLight={rubikons[6]} />
      <Orb nn="2_2" percent={props.percent} percentToLight={rubikons[7]} />
      <Orb nn="2_3" percent={props.percent} percentToLight={rubikons[8]} />
      <Orb nn="2_4" percent={props.percent} percentToLight={rubikons[9]} />

      <Orb nn="3_1" percent={props.percent} percentToLight={rubikons[10]} />
      <Orb nn="3_2" percent={props.percent} percentToLight={rubikons[11]} />
      <Orb nn="3_3" percent={props.percent} percentToLight={rubikons[12]} />
    </div>
  )
}
