import { Tab } from '../../../ui/Tab'
import { Tabs } from '../../../ui/Tabs'
import style from './HallOfFame.module.scss'
import { HallOfFameSeason1 } from './HallOfFameSeason1'
import { HallOfFameSeason2 } from './HallOfFameSeason2'

export const HallOfFame = () => {
  const tabs = [
    { name: 'season1', title: 'Первый сезон' },
    { name: 'season2', title: 'Второй сезон' },
  ]

  return (
    <div className={style.hallOfFame}>
      <Tabs tabs={tabs}>
        <Tab name="season1">
          <HallOfFameSeason1 />
        </Tab>
        <Tab name="season2">
          <HallOfFameSeason2 />
        </Tab>
      </Tabs>
    </div>
  )
}
