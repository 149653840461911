import React from 'react'
import style from './Tabs.module.scss'
import cn from 'classnames'
import { yandexTargetApi } from '../../api/yandex.api'

interface Props {
  children: React.ReactNode[]
  tabs: {
    name: string
    title?: string
  }[]
}

interface EnrichedChildren {
  isSelected: boolean
  name: string
}

export const Tabs: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = React.useState(props.tabs[0].name)

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (!React.isValidElement<EnrichedChildren>(child)) {
      return child
    }

    let elementChild: React.ReactElement<EnrichedChildren> = child

    if (React.isValidElement(elementChild)) {
      return React.cloneElement(elementChild, { isSelected: elementChild.props.name === selectedTab })
    }
    return elementChild
  })

  function onTabClick(tabName: string) {
    setSelectedTab(tabName)
    if (tabName === 'bio') yandexTargetApi('click_bio_tab')
    if (tabName === 'rules') yandexTargetApi('click_rules_tab')
    if (tabName === 'texts') yandexTargetApi('click_texts_tab')
    if (tabName === 'game') yandexTargetApi('click_game_tab')
    if (tabName === 'nominations') yandexTargetApi('click_nominations_tab')
    if (tabName === 'results') yandexTargetApi('click_results_tab')
    if (tabName === 'bets') yandexTargetApi('click_bets_tab')
    if (tabName === 'settings') yandexTargetApi('click_settings_tab')
    if (tabName === 'halloffame') yandexTargetApi('click_halloffame_tab')
  }

  return (
    <>
      <div className={style.tabs}>
        {props.tabs.map((tab, index) => {
          return (
            <span className={cn(style.tabLink, { [style.selected]: selectedTab === tab.name })} key={index} onClick={() => onTabClick(tab.name)}>
              {tab.title}
            </span>
          )
        })}
      </div>
      {childrenWithProps}
    </>
  )
}
