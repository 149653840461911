import React from 'react'

export const Index = () => {
  return (
    <div className="indexPage">
      {/* <a href="/season1" className="indexLink">
        Итоги 1 сезона Грелочки
      </a> */}
      <div>
        Чтобы войти в профиль, <br /> нужен уникальный id
      </div>
    </div>
  )
}
