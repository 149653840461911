import React from 'react'
import style from './HallOfFame.module.scss'
import { pluralSets, pluralText } from '../../../../methods/text.methods'
import cn from 'classnames'
import { PLAY_TEXT, SKIP_TEXT } from '../../../../data/users'

interface TextRowProps {
  cellData: [string, number, number?]
  index: number
  place: number
}

export const TextRow = (props: TextRowProps) => {
  const textName = props.cellData[0]
  const balls = props.cellData[1]
  const place = props.cellData[2] || props.place + 1

  if (textName === SKIP_TEXT) {
    return <div className={style.noText}>★ Грелочка {props.index}: Не участвует ❌</div>
  }

  if (textName === PLAY_TEXT) {
    return <div className={style.noText}>★ Грелочка {props.index}: Участвует сейчас ⏳</div>
  }

  return (
    <>
      <div className={style.textTitle}>
        ★ Грелочка {props.index}: «{textName}»: {pluralText(balls === -1 ? 0 : balls, pluralSets.balls)}
        {balls > 0 && place > 0 && (
          <div
            className={cn(style.badge, {
              [style.gold]: place === 1,
              [style.silver]: place === 2,
              [style.copper]: place === 3,
              [style.gray]: place > 3,
            })}
          >
            {place} место
          </div>
        )}
        {balls === 0 && <div className={cn(style.badge, style.black)}>Могила 💀</div>}
        {balls === -1 && <div className={cn(style.badge, style.black)}>Бан 💀</div>}
      </div>
    </>
  )
}
