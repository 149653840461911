import { Align } from 'chart.js'
import { VoteAdmin } from '../../../api/user.api'
import { UserAdmin } from '../../../types/types'

export function generateChart(usersRaw: UserAdmin[], votesAdmin: VoteAdmin[]) {
  // @ts-ignore
  const users = [...usersRaw.map((user) => ({ ...user, id: parseInt(user.id) }))] // Фикс того, что id почему-то приходит стрингом
  users.sort(function (a, b) {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  })
  // console.log('### users:', users);

  const emptyState: any = {}
  const step = 0.0025

  Object.values(users).forEach((user, index) => {
    emptyState[user.id] = step * index
  })

  const votes = votesAdmin.map((vote) => {
    return [vote.fromUser.id, vote.toUser.id, vote.rating]
  })

  const stages = []

  for (let index = 0; index < votes.length; index++) {
    const vote = votes[index]
    const [, to, value] = vote

    let prevResult
    if (index === 0) {
      prevResult = { ...emptyState }
    } else {
      prevResult = stages[index - 1]
    }

    const newResult: any = {
      ...prevResult,
      [to]: prevResult[to] + value,
    }

    stages.push(newResult)
  }
  stages.unshift(emptyState)
  // console.log('stages:', stages);

  const matrix = stages.map((stage) => {
    return Object.entries(stage).map(([key, value]) => value)
  })
  // console.log('matrix:', matrix);

  const transMatrix = matrix[0].map((_, columnIndex) => matrix.map((row) => row[columnIndex]))
  // console.log('transMatrix:', transMatrix);

  const datasets = Object.entries(transMatrix).map(([key, value], index) => {
    const randomColor = '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
    // const findUser = dbUsers.find((user) => user.id == users[index].id);
    let label = users[index].name

    // const SHOW_NAMES = false;
    // const SHOW_TEXTNAME = false;

    // if (SHOW_NAMES && findUser) {
    //   label += ` (${findUser.name})`;
    // }
    // if (SHOW_TEXTNAME) {
    //   label += ` (${users[index].textName})`;
    // }
    return {
      id: users[index].id,
      // textName: users[index].textName,
      // characters: users[index].characters,
      label: label,
      backgroundColor: randomColor,
      borderColor: randomColor,
      fill: false,
      data: value,
      pointRadius: 1,
      borderWidth: 3,
      // banned: users[index].banned,
      // tension: 0.5,
    }
  })

  let cemetryRendered = false
  // const [cemetryRendered, set_cemetryRendered] = React.useState(false);
  let renderedPositions: number[] = []

  const racingChart = {
    type: 'line',
    data: {
      labels: Array.from(Array(votes.length + 1).keys()),
      datasets: datasets,
    },
    options: {
      // elements: {
      //   line: {
      //     tension: 50,
      //   },
      // },
      layout: {
        padding: {
          right: 160,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: 'white',
            padding: 10,
          },
          // title: {
          //   padding: 200,
          // },
          // position: 'bottom',
        },
        tooltip: {
          intersect: false,
        },
        datalabels: {
          anchor: 'end' as Align,
          align: 'right' as Align,
          color: 'white',
          display: function (context: any) {
            return context.dataIndex === votes.length
          },
          formatter: function (value: number, context: any) {
            const valueRounded = Math.round(value)
            if (valueRounded === 0) {
              if (!cemetryRendered) {
                cemetryRendered = true
                // set_cemetryRendered(true);
                return `Братская могила 💀`
              } else {
                return null
              }
            }
            const datasetHasValues = datasets.filter((user) => {
              // @ts-ignore
              const endResult: number = user.data[user.data.length - 1]
              return Math.round(endResult) === valueRounded
            })
            if (datasetHasValues.length > 0 && !renderedPositions.includes(valueRounded)) {
              let text = ''
              datasetHasValues.forEach((user) => {
                text += `${valueRounded} - ${user.label}\n`
              })
              renderedPositions.push(valueRounded)
              return text
            } else {
              const title = context.dataset.label
              return `${valueRounded} - ${title}`
            }
          },
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          // stacked: true,
          grid: {
            display: true,
            color: 'rgba(155,155,255,0.25)',
          },
          title: {
            display: true,
            text: 'Баллы',
          },
          ticks: {
            // stepSize: 5,
          },
        },
        x: {
          grid: {
            display: true,
          },
        },
      },
    },
  }

  // console.log('### racingChart:', racingChart);

  return racingChart
}
