import { Line } from 'react-chartjs-2'
import { VoteAdmin } from '../../../api/user.api'
import { UserAdmin } from '../../../types/types'
import 'chart.js/auto' // ADD THIS
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'
import { generateChart } from './chartGenerator'
Chart.register(ChartDataLabels)

interface Props {
  votes: VoteAdmin[]
  users: UserAdmin[]
}

export const Ranking = (props: Props) => {
  const racingChart = generateChart(props.users, props.votes)
  const [test, setTest] = React.useState(1)

  return (
    <>
      <div className="chart">
        <Line data={racingChart.data} options={racingChart.options} id="chart" width={500} height={800} />
      </div>
      <button onClick={() => setTest(test + 1)}>Refresh</button>
    </>
  )
}
