import { fameUsers, SKIP_TEXT } from '../../../../data/users'
import { AchievementsList } from '../components/AchievementsList'
import { NominationsList } from '../components/NominationsList'
import style from './HallOfFame.module.scss'
import cn from 'classnames'
import { TextRow } from './HallOfFame.textRow'

export const HallOfFameSeason2 = () => {
  const fameUsersSorted = [...fameUsers]
  fameUsersSorted.sort(function (a, b) {
    const summA = a.round6[1]
    const summB = b.round6[1]
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  const fameUserSortedByRound6 = [...fameUsers]
  fameUserSortedByRound6.sort(function (a, b) {
    const valueA = a.round6[1]
    const valueB = b.round6[1]
    if (valueA > valueB) return -1
    if (valueA < valueB) return 1
    return 0
  })

  return (
    <>
      {fameUsersSorted.map((user, index) => {
        // const anyBan = user.round3[1] === -1;
        const anyBan = false
        const summ = user.round6[1] + (anyBan ? 1 : 0)

        const isVeteran =
          user.round1[0] !== SKIP_TEXT &&
          user.round2[0] !== SKIP_TEXT &&
          user.round3[0] !== SKIP_TEXT &&
          user.round4[0] !== SKIP_TEXT &&
          user.round5[0] !== SKIP_TEXT &&
          user.round6[0] !== SKIP_TEXT

        const round6place = fameUserSortedByRound6.findIndex((_user) => _user.name === user.name)
        return (
          <div className={style.userRow} key={index}>
            <h3>
              #{index + 1}: {user.name}
            </h3>
            <div className={style.userGrid}>
              <div className={style.gridCell}>
                {isVeteran && <div className={cn(style.badge, style.purple)}>Ветеран всех грелочек!</div>}
                <br />
                <br />

                <TextRow index={6} cellData={user.round6} place={round6place} />

                <br />

                {user.achievements.length > 0 && (
                  <>
                    <h3>Достижения:</h3>
                    <AchievementsList achievements={user.achievements} smallMode />
                  </>
                )}

                {user.nominations.length > 0 && (
                  <>
                    <h3>Выигранные номинации:</h3>
                    <NominationsList nominations={user.nominations} nominationsWon={[]} smallMode />
                  </>
                )}
              </div>
              <div className={cn(style.gridCell, style.balls)}>
                <div>Всего баллов</div>
                <div className={style.ballsCount}>{summ}</div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
