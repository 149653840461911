import style from './Loading.module.scss'

interface Props {
  isLoading: boolean
}

export const LoadingModal = (props: Props) => {
  if (!props.isLoading) return null

  return (
    <div className={style.modal}>
      <img src="/img/loading.gif" alt="" />
      <div>Уже бегу! </div>
      <div>Сейчас всё загрузится!</div>
    </div>
  )
}
