import { AxiosResponse } from 'axios'
import { axiosInstance } from './adapter'
import { ExtraVoteType, ExtraVoteValue, NominationType, ProfileState } from '../store/profile.slice'
import { Coin, Text, TextAdmin, UserAdmin } from '../types/types'
import { GameState } from '../store/game.slice'
import { ResultsState } from '../store/results.slice'
import { BetsState } from '../store/bets.slice'

export function getProfileByGuidApi(guid: string): Promise<AxiosResponse<ProfileState, any>> {
  return axiosInstance.get(`/profile/${guid}`)
}

export function getTextsByGuidApi(guid: string): Promise<AxiosResponse<Text[], any>> {
  return axiosInstance.get(`/texts/${guid}`)
}

export function getPlayersByGuidApi(guid: string): Promise<AxiosResponse<GameState, any>> {
  return axiosInstance.get(`/players/${guid}`)
}

export function getResultsByGuidApi(guid: string): Promise<AxiosResponse<ResultsState, any>> {
  return axiosInstance.get(`/my_results/${guid}`)
}

export interface ExtraVoteBody {
  guid: string
  textIndex: number
  type: ExtraVoteType
  value: ExtraVoteValue
}

export interface ExtraVoteClearBody {
  guid: string
  textIndex: number
}

export function postExtraVote(extraVoteBody: ExtraVoteBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/extra_vote/`, extraVoteBody)
}

export function postExtraVoteClear(extraVoteClearBody: ExtraVoteClearBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/extra_vote_clear/`, extraVoteClearBody)
}

export type VoteAdmin = {
  rating: number
  created_at: string
  fromUser: {
    id: string
    name: string
  }
  toUser: {
    id: string
    name: string
    textName: string
  }
}

export function getVotesByGuidApi(guid: string): Promise<AxiosResponse<VoteAdmin[], any>> {
  return axiosInstance.get(`/votes/${guid}`)
}

export function getUsersByGuidApi(guid: string): Promise<AxiosResponse<UserAdmin[], any>> {
  return axiosInstance.get(`/users/${guid}`)
}

export function getTextsAdminByGuidApi(guid: string): Promise<AxiosResponse<TextAdmin[], any>> {
  return axiosInstance.get(`/textsAdmin/${guid}`)
}

export interface NominationBody {
  guid: string
  textIndex: number
  type: NominationType
}

export function postNominationApi(nominationBody: NominationBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/nominations/`, nominationBody)
}

export interface ReviewBody {
  guid: string
  textIndex: number
  text: string
}

export function postReviewApi(reviewBody: ReviewBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/reviews/`, reviewBody)
}

export interface BetBody {
  guid: string
  textIndex: number
  amount: number
  coin: Coin
}

export function postBetApi(reviewBody: BetBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/bets/`, reviewBody)
}

export interface GuessBody {
  guid: string
  textIndex: number
  userName: string
}

export function postGuessApi(nominationBody: GuessBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/guesses/`, nominationBody)
}

export function getBetsByGuidApi(guid: string): Promise<AxiosResponse<BetsState, any>> {
  return axiosInstance.get(`/bets/${guid}`)
}

export function postContactApi(contact: string, about: string): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/invite_me_to_a_secret_society/`, {
    contact,
    about,
  })
}

export interface ChangeBalanceBody {
  guid: string
  userId: number
  amount: number
  coin: Coin
}

export function postChangeBalance(body: ChangeBalanceBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/balance/`, body)
}

export interface CreateBalanceBody {
  guid: string
  userId: number
}

export function postCreateBalance(body: CreateBalanceBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/balance/create`, body)
}

export interface ChangeNameBody {
  guid: string
  type: ClanTypeName
}

export type ClanTypeName = 'askaron' | 'pushok' | 'dranik' | 'bes'
export type ClanType = 0 | 1 | 2 | 3 | 4

export function postChangeName(body: ChangeNameBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/change_name`, body)
}
