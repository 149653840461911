import React from 'react'
import { NominationItem } from '../../../../data/nominations'
import style from './NominationBlock.module.scss'
import cn from 'classnames'

interface Props {
  count: number
  withBg?: boolean
  item: NominationItem
  isWin?: boolean
  smallMode?: boolean
}

export const NominationBlock = (props: Props) => {
  const [showModal, set_showModal] = React.useState(false)

  function toggleModal() {
    set_showModal(!showModal)
  }

  return (
    <>
      <div className={style.wrapper}>
        <div
          className={cn(style.nomination, {
            [style.bg]: props.withBg,
            [style.border]: props.isWin,
            [style.small]: props.smallMode,
          })}
          onClick={toggleModal}
        >
          <div className={style.icon}>{props.item.icon}</div>
          {!props.smallMode && <div className={style.title}>{props.item.title}</div>}
          <div className={style.count}>{props.count > 1 ? `✖${props.count}` : ''}</div>
        </div>
        {props.isWin && <div className={style.winner}>Победа</div>}
      </div>

      {showModal && (
        <div className={style.infoModal}>
          <div className={style.info}>
            <p className={style.itemType}>{props.item.itemType === 'achievement' ? 'ачивка' : 'номинация'}</p>
            <h2>{props.item.icon}</h2>
            <h3>{props.item.title}</h3>
            <p>{props.item.description}</p>
            <div>★</div>
            {props.item.itemType === 'achievement' && <p>Ачивки выдаются админом вручную на основе турнирной таблицы и событий</p>}
            {props.item.itemType === 'nomination' && <p>Номинации выдаются участниками на этапе чтения и голосования</p>}

            {props.isWin && <div>★</div>}
            {props.isWin && (
              <div className={style.winText}>
                <div>Эта номинация была выиграна</div>
                <br />
                <div>Она станет ачивкой</div>
              </div>
            )}
            <button onClick={toggleModal}>Теперь понятно!</button>
          </div>
        </div>
      )}
    </>
  )
}
