import style from './MyClan.module.scss'
import { ClanTypeName, postChangeName } from '../../../api/user.api'
import { useParams } from 'react-router-dom'
import { getProfileMethod } from '../../../methods/app.methods'
import { ProfileState } from '../../../store/profile.slice'
import { clanDecoder, clanInfo } from '../../../data/statusDecoder'
import cn from 'classnames'
import { pluralSets, pluralText } from '../../../methods/text.methods'
import { AudioManager } from './components/AudioManager'
import React from 'react'

interface Props {
  profile: ProfileState
}

export const MyClan = (props: Props) => {
  let { guid = '' } = useParams()
  const [audioTick, set_audioTick] = React.useState(0)

  function changeName(type: ClanTypeName) {
    const body = {
      guid,
      type,
    }
    postChangeName(body)
      .then(() => {
        set_audioTick(audioTick + 1)
        getProfileMethod(guid)
      })
      .catch(() => alert('А всё! Больше имя нельзя поменять!'))
  }

  const myClan = props.profile.myClan
  const myClanPowerFactor = myClan.length > 0 ? 1 / myClan.length : 1
  const myClanPower = myClan.reduce((acc, clanUser) => acc + Math.ceil(clanUser.power * myClanPowerFactor), 0)

  const myClanSortedByPower = [...myClan]
  myClanSortedByPower.sort(function (a, b) {
    if (a.power < b.power) return 1
    if (a.power > b.power) return -1
    return 0
  })

  const clanPluralSet = {
    0: pluralSets.people,
    1: pluralSets.askarons,
    2: pluralSets.pushki,
    3: pluralSets.draniki,
    4: pluralSets.beses,
  }

  return (
    <>
      <AudioManager tick={audioTick} type="sword" />

      {props.profile.clan === 0 && (
        <>
          <div className={style.nameChange}>
            <h2>Грядёт битва племён!</h2>

            <div>После вступления в племя ты получишь случайное племенное имя!</div>
            <div>Твои соплеменники будут уважать тебя...</div>
            <div>Но на тебе будет и большая ответственность, ведь ты должен принести славу своему племени!</div>
            <div>Делай выбор мудро!</div>

            <br />
            <br />

            <button onClick={() => changeName('askaron')}>Я Аскарон!</button>
            <div className={style.clanInfo}>{clanInfo[1]}</div>

            <button onClick={() => changeName('pushok')}>Я Пушок!</button>
            <div className={style.clanInfo}>{clanInfo[2]}</div>

            <button onClick={() => changeName('dranik')}>Я Драник!</button>
            <div className={style.clanInfo}>{clanInfo[3]}</div>

            <button onClick={() => changeName('bes')}>Я Бес!</button>
            <div className={style.clanInfo}>{clanInfo[4]}</div>
          </div>
        </>
      )}

      {props.profile.clan !== 0 && (
        <>
          <div className={style.clanPage}>
            <div className={style.instruction}>
              Мощь набирается при выполнении основных и дополнительных задач игры, племена активно тыкают кнопочки, чтобы победить в битве. <br />
              <br /> Мощь племени делится пропорционально количеству соплеменников, чтобы у всех были шансы на победу.
            </div>

            {/* Мой клан */}
            <div className={cn(style.clan, style.myClan)}>
              <div className={style.clanHeader}>
                <h2>Мои «{clanDecoder[props.profile.clan]}»</h2>
                <div className={style.clanPower}>Мощь племени: {Math.ceil(myClanPower)} ⚡️</div>
                <div className={style.clanInfo}>{clanInfo[props.profile.clan]}</div>
              </div>
              <div className={style.clanUsers}>
                <h3>{pluralText(myClanSortedByPower.length, clanPluralSet[props.profile.clan])}</h3>
                {myClanSortedByPower.map((clanUser, index) => {
                  return (
                    <div key={index} className={style.clanUser}>
                      <div className={style.name}>{clanUser.name}</div>
                      <div className={style.power}>{Math.ceil(clanUser.power * myClanPowerFactor)} ⚡️</div>
                    </div>
                  )
                })}
              </div>
            </div>

            {/* Другие кланы */}
            <div className={style.anotherClans}>
              {props.profile.anotherClans.map((anotherClan, index) => {
                const clanPowerFactor = 1 / anotherClan.users.length
                const clanPower = anotherClan.users.reduce((acc, clanUser) => acc + Math.ceil(clanUser.power * clanPowerFactor), 0)

                const usersSortedByPower = [...anotherClan.users]
                usersSortedByPower.sort(function (a, b) {
                  if (a.power < b.power) return 1
                  if (a.power > b.power) return -1
                  return 0
                })

                return (
                  <div className={style.clan} key={index}>
                    <div className={style.clanHeader}>
                      <h2>«{clanDecoder[anotherClan.clan]}»</h2>
                      <div className={style.clanPower}>Мощь племени: {Math.ceil(clanPower)} ⚡️</div>
                      <div className={style.clanInfo}>{clanInfo[anotherClan.clan]}</div>
                    </div>
                    <div className={style.clanUsers}>
                      <h3>{pluralText(anotherClan.users.length, clanPluralSet[anotherClan.clan])}</h3>
                      {usersSortedByPower.map((clanUser, index) => {
                        return (
                          <div key={index} className={style.clanUser}>
                            <div className={style.name}>{clanUser.name}</div>
                            <div className={style.power}>{Math.ceil(clanUser.power * clanPowerFactor)} ⚡️</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}
