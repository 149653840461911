import React from 'react'
import { Text } from '../../types/types'
import { ExtraVote, ExtraVoteType, ExtraVoteValue, profileSlice } from '../../store/profile.slice'
import { postExtraVote } from '../../api/user.api'
import { useParams } from 'react-router-dom'
import { grelkaStore } from '../../store/store'
import { AudioManager } from '../pages/user/components/AudioManager'

interface Props {
  text: Text
  index: number
  userExtraVotes: ExtraVote[]
}

interface Question {
  title: string
  type: ExtraVoteType
  answers: [string, string, string]
}

const questions: Question[] = [
  {
    title: 'Понравился ли текст в целом?',
    type: 'like',
    answers: ['Текст не понравился', 'Текст средний', 'Текст понравился'],
  },
  {
    title: 'Хорошо ли раскрыта тема?',
    type: 'theme',
    answers: ['Тема не раскрыта', 'Тема раскрыта средне', 'Тема раскрыта хорошо'],
  },
  {
    title: 'Легко ли читается текст?',
    type: 'readable',
    answers: ['Читать тяжело', 'Читается средне', 'Читается хорошо'],
  },
  {
    title: 'Понравился ли стиль текста?',
    type: 'style',
    answers: ['Стиль не понравился', 'Стиль обычный', 'Стиль приятный'],
  },
]

export const TextExtraVote = (props: Props) => {
  let { guid = '' } = useParams()
  const [selectedTypes, set_selectedTypes] = React.useState<ExtraVoteType[]>([])
  const [audioTick, set_audioTick] = React.useState(0)

  function sendExtraVote(type: ExtraVoteType, value: ExtraVoteValue) {
    set_selectedTypes([...selectedTypes, type])
    postExtraVote({
      guid,
      textIndex: props.index,
      type,
      value,
    }).then((response) => {
      grelkaStore.dispatch(profileSlice.actions.addExtraVote(response.data))
      set_audioTick(audioTick + 1)
    })
  }

  return (
    <div className="textModal">
      <AudioManager tick={audioTick} type="chpok" />
      {questions.map((question, index) => {
        const hasSelectedNow = selectedTypes.includes(question.type)
        const hasSelectedBefore = props.userExtraVotes.filter((extraVote) => extraVote.textIndex === props.index && extraVote.type === question.type).length > 0
        if (hasSelectedNow) return null
        if (hasSelectedBefore) return null
        return (
          <div className="extraVoteBlock" key={index}>
            <div className="title">{question.title}</div>
            <button className="red" onClick={() => sendExtraVote(question.type, 1)}>
              {question.answers[0]}
            </button>
            <button className="yellow" onClick={() => sendExtraVote(question.type, 2)}>
              {question.answers[1]}
            </button>
            <button className="green" onClick={() => sendExtraVote(question.type, 3)}>
              {question.answers[2]}
            </button>
          </div>
        )
      })}
    </div>
  )
}
