import { Bar } from 'react-chartjs-2'
import style from './Season1.module.scss'

export const Season1 = () => {
  const labelsdefault = ['1 грелочка', '2 грелочка', '3 грелочка', '4 грелочка', '5 грелочка']
  const colorsDefault = ['#f5487f', '#c7417b', '#8f3b76', '#553772', '#1f306e', '#311e5e']

  const data_players = {
    labels: labelsdefault,
    datasets: [
      {
        label: 'Игроки',
        data: [15, 12, 22, 18, 23],
        backgroundColor: colorsDefault,
      },
    ],
  }

  const data_texts = {
    labels: labelsdefault,
    datasets: [
      {
        label: 'Тексты',
        data: [15, 12, 23, 18, 25],
        backgroundColor: colorsDefault,
      },
    ],
  }

  const data_characters = {
    labels: labelsdefault,
    datasets: [
      {
        label: 'Знаки',
        data: [98179, 90892, 150272 + 9960, 122734, 151559 + 9998 + 5669],
        backgroundColor: colorsDefault,
      },
    ],
  }

  // const characters_all = [
  //   6057, 10000, 4401, 6892, 3622, 6584, 2101, 3358, 9725, 7893, 4856, 9945, 5768, 7927, 9050, 6970, 9999, 9450, 9997, 10001, 6845, 4655, 3064, 7207, 10012,
  //   9437, 3255, 9758, 9964, 2746, 4994, 4779, 2386, 8109, 9790, 8912, 8971, 9900, 7111, 2549, 3801, 10309, 5880, 2742, 11857, 8329, 4002, 7838, 5545, 4812,
  //   9991, 3772, 2140, 9777, 2541, 4900, 9974, 9976, 6963, 3179, 9051, 9636, 8027, 7742, 5047, 9997, 5209, 10000, 7403, 1714, 7589, 7057, 4552, 9660, 6322, 7463,
  //   1663, 1817, 4095, 5087, 3854, 10000, 6822, 5425, 9674, 8902, 5555, 7618, 9324, 9963, 9960, 9998, 5669,
  // ];
  // characters_all.sort((a, b) => a - b);
  // const data_characters_all = {
  //   labels: Array.from(Array(characters_all.length).keys()),
  //   datasets: [
  //     {
  //       label: 'Знаки по каждому тексту',
  //       data: characters_all,
  //       backgroundColor: colorsDefault,
  //     },
  //   ],
  // };

  const WIDTH = 400
  const MAXHEIGHT = 800
  const HEIGHT = 400 < MAXHEIGHT ? 400 : MAXHEIGHT

  return (
    <div className={style.season}>
      <h1>Первый сезон (2024) закончился!</h1>
      <hr />

      <h2>В нём принял участие 31 участник, из них 6 участвовали в каждой игре</h2>
      <div className={style.wrapper}>
        <div className={style.chart} style={{ width: `${WIDTH}px` }}>
          <Bar data={data_players} width={WIDTH} height={HEIGHT} id="races" />
        </div>
      </div>

      <h2>Всего было сдано, прочитано и оценено 93 текста</h2>
      <div className={style.wrapper}>
        <div className={style.chart} style={{ width: `${WIDTH}px` }}>
          <Bar data={data_texts} width={WIDTH} height={HEIGHT} id="races" />
        </div>
      </div>

      <h2>Суммарно было написано 639.263 знака</h2>
      <div className={style.wrapper}>
        <div className={style.chart} style={{ width: `${WIDTH}px` }}>
          <Bar data={data_characters} width={WIDTH} height={HEIGHT} id="races" />
        </div>
      </div>
      {/* <div className={style.wrapper}>
        <div className={style.chart} style={{ width: `${WIDTH * 2}px` }}>
          <Bar data={data_characters_all} width={WIDTH} height={HEIGHT} id="races" />
        </div>
      </div> */}

      <h2>Мы пережили 5 непростых тем, особенно четвёртую...</h2>
      <ul>
        <li>Изменения</li>
        <li>Цена желания</li>
        <li>Сопротивление</li>
        <li>Две стороны одной монеты 💀</li>
        <li>Возвращение</li>
      </ul>

      <h2>А как всё начиналось...</h2>
      <p>
        А начиналось всё с того, что мы просто хотели поделиться текстами, и Я. предложил сделать свою Грелку. Грелочку. И мы играли через наскоро написанного
        бота, который каким-то образом сумел с этим справиться. Дальше — больше. Появился сайт, который стремительно стал обрастать новыми функциями и
        заданиями. Были пчолы. Были арбузы. Были братские могилы и таймеры. Номинации и ачивки. Была Ленка, была Латвия. Были драники, с яйцом и без, и даже с
        шоколадом. Анекдоты в рецензиях. И пушки (их теперь два, один побольше).
      </p>

      <h2>А дальше что?</h2>
      <p>
        А дальше — второй сезон в 2025 году. Уже в январе. Или в феврале. Но он точно будет. С чего бы ему не быть? Грелочка едет по своим рельсам, и её не
        остановит ничто!
      </p>

      <div className={style.imgWrapper}>
        <img src="/img/bee2.jpg" alt="" />
      </div>
    </div>
  )
}
