import { NominationType } from '../store/profile.slice'

export interface NominationItem {
  type: NominationType
  title: string
  description: string
  icon: string
  itemType: 'nomination' | 'achievement'
}

export const nominationsList: NominationItem[] = [
  { type: 'standup', title: 'Стендап', description: 'Самый смешной текст', icon: '😁', itemType: 'nomination' },
  { type: 'drama', title: 'Драматург', description: 'Самый драматичный текст', icon: '😭', itemType: 'nomination' },
  { type: 'nabokov', title: 'Филолог', description: 'Самые необычные словесные обороты', icon: '🤓', itemType: 'nomination' },
  { type: 'mimimi', title: 'Мимими', description: 'Самый милый текст :3', icon: '🥰', itemType: 'nomination' },
  { type: 'chef', title: 'Шеф-повар', description: 'Текст, в котором было много еды', icon: '🍔', itemType: 'nomination' },
  { type: 'ogo', title: 'Ого!', description: 'Текст, в котором было что-то очень неожиданное', icon: '😱', itemType: 'nomination' },
  { type: 'not_ready', title: 'Мир ещё не готов', description: 'Самый недопонятый текст', icon: '🧐', itemType: 'nomination' },
  { type: 'literally', title: 'В лоб', description: 'Буквальная интерпретация темы', icon: '😎', itemType: 'nomination' },
  { type: 'interesting', title: 'Глубоко', description: 'Неочевидная интерпретация темы', icon: '👽', itemType: 'nomination' },
  { type: 'normie', title: 'Нормис', description: 'Самый нормальный текст. Что бы это значило?', icon: '😐', itemType: 'nomination' },
  { type: 'light', title: 'Воин света', description: 'Самый светлый текст', icon: '🙏', itemType: 'nomination' },
  { type: 'dark', title: 'Воин тьмы', description: 'Самый мрачный текст', icon: '👹', itemType: 'nomination' },
  // { type: 'hot', title: 'Жаркий текст', description: 'Было жарко! 🔥', icon: '🔥', itemType: 'nomination' },
  // { type: 'cold', title: 'Холодный текст', description: 'Было холодно... ❄️', icon: '❄️', itemType: 'nomination' },
  { type: 'scary', title: 'Страшно', description: 'Этот текст был страшным', icon: '😱', itemType: 'nomination' },
  { type: 'cosy', title: 'Уютно', description: 'Уютненько', icon: '🤗', itemType: 'nomination' },
  // { type: 'hard', title: 'Сложна! Нипанятна!', description: 'Я ничего не понял :(', icon: '😵‍💫', itemType: 'nomination' },
  { type: 'lore', title: 'Лорно', description: 'Лор принимает только с медицинским полисом!', icon: '⚙️', itemType: 'nomination' },
  // { type: 'less_dialogs', title: 'А поговорить?', description: 'В тексте было мало диалогов или не было вовсе', icon: '😶', itemType: 'nomination' },
  // { type: 'many_dialogs', title: 'Больше дела!', description: 'В тексте было слишком много диалогов', icon: '🥱', itemType: 'nomination' },
  { type: 'educational', title: 'Познавательно', description: 'В тексте было то, что интересно узнать', icon: '🤔', itemType: 'nomination' },
  { type: 'massacre', title: 'РЕЗНЯ', description: 'Убивать! Убивать! Убивать! Убивать! Убивать!!!', icon: '🔪', itemType: 'nomination' },
  // { type: 'horny', title: 'Хорни', description: '', icon: '😳', itemType: 'nomination' },
  // 😱😨🤗😵‍💫🤓😶🥱🤔🔪🥰😳
]

export const achievementsList: NominationItem[] = [
  { type: '1st', title: 'Первое место', description: 'Первое место в любой игре', icon: '🥇', itemType: 'achievement' },
  { type: '2nd', title: 'Второе место', description: 'Второе место в любой игре', icon: '🥈', itemType: 'achievement' },
  { type: '3rd', title: 'Третье место', description: 'Третье место в любой игре', icon: '🥉', itemType: 'achievement' },
  {
    type: 'veteran',
    title: 'Ветеран',
    description: 'Участвует во всех играх сезона. Эта ачивка может быть потеряна, если пропустить игру',
    icon: '🏅',
    itemType: 'achievement',
  },
  { type: 'pass', title: 'Прогульщик', description: 'Пропустил как минимум одну игру, но вернулся и играл снова', icon: '😓', itemType: 'achievement' },
  {
    type: 'dead',
    title: 'Житель могилы',
    description: 'Отдых в комфортных подземных апартаментах (набрано 0 баллов)',
    icon: '💀',
    itemType: 'achievement',
  },
  {
    type: 'lowhp',
    title: 'Выживший',
    description: 'На самом краю! Не был в могиле, но набрал меньше всех баллов в игре',
    icon: '👀',
    itemType: 'achievement',
  },
  { type: 'ban', title: 'Жертва дедлайна', description: 'Не успел проголосовать', icon: '☠️', itemType: 'achievement' },
  { type: 'oversize', title: 'Невпихуемое', description: 'Текст вышел за лимиты в 10000 знаков', icon: '🐳', itemType: 'achievement' },
  { type: 'longest', title: 'Слоняра', description: 'Текст был длиннее остальных (но не нарушил правила)', icon: '🐘', itemType: 'achievement' },
  { type: 'shortest', title: 'Пчола', description: 'Текст был короче остальных (но не нарушил правила)', icon: '🐝', itemType: 'achievement' },
  { type: 'fluffy', title: 'Пушок', description: 'Выдаётся за убеждения', icon: '🌸', itemType: 'achievement' },
  { type: 'potato', title: 'Орден драника', description: 'Написал текст про драники (когда же это кончится?)', icon: '🌚', itemType: 'achievement' },
  { type: 'graphoman', title: 'Графоман', description: 'Сдал больше одного текста', icon: '😎', itemType: 'achievement' },
  {
    type: 'illustrator',
    title: 'Иллюстратор',
    description: 'Добавил иллюстрацию к своему тексту. Учитываются только информативные иллюстрация, но не мемы',
    icon: '🎨',
    itemType: 'achievement',
  },
  { type: 'dominant', title: 'Доминатор', description: 'Удерживал призовое место более 1 игры подряд', icon: '👑', itemType: 'achievement' },
  { type: 'seasonwinner', title: 'Победитель сезона', description: 'Выиграл в сезоне в общем зачёте по числу баллов', icon: '🏆', itemType: 'achievement' },
  {
    type: 'other',
    title: 'Иной',
    description: 'Написал что-то очень необычное или в необычном формате',
    icon: '🍄',
    itemType: 'achievement',
  },
  { type: 'like', title: 'Молодец', description: 'Получил наибольшую оценку в категории "Общее впечатление"', icon: '💛', itemType: 'achievement' },
  { type: 'theme', title: 'Выкупил тему', description: 'Получил наибольшую оценку в категории "Соответствие теме"', icon: '🎯', itemType: 'achievement' },
  { type: 'readable', title: 'Всё понятно', description: 'Получил наибольшую оценку в категории "Читабельность"', icon: '☀️', itemType: 'achievement' },
  { type: 'style', title: 'Стиляга', description: 'Получил наибольшую оценку в категории "Красота текста"', icon: '✨', itemType: 'achievement' },
  { type: 'nominant10', title: 'Номинант', description: 'Собрано 10 номинаций за одну игру', icon: '🌵', itemType: 'achievement' },
  { type: 'nominant20', title: 'Меганоминант', description: 'Собрано 20 номинаций за одну игру', icon: '🎄', itemType: 'achievement' },
  {
    type: 'no_nominations',
    title: 'Я выше этого',
    description: 'Не получил ни одной номинации за игру, потому что не очень-то и хотелось!',
    icon: '🙅',
    itemType: 'achievement',
  },
  { type: 'betwin', title: 'Поднял бабла', description: 'Выиграл на ставках', icon: '💸', itemType: 'achievement' },
  { type: 'betlose', title: 'Без штанов', description: 'Проиграл на ставках', icon: '🎺', itemType: 'achievement' },
  { type: '1season', title: 'Игрок 1 сезона', description: 'Играл в 1 сезоне грелочки', icon: '1️⃣', itemType: 'achievement' },
  { type: '2season', title: 'Игрок 2 сезона', description: 'Играл во 2 сезоне грелочки', icon: '2️⃣', itemType: 'achievement' },
  { type: 'clan', title: 'Командный игрок', description: 'Принёс своему племени победу', icon: '📢', itemType: 'achievement' },
]
