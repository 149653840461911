// Выбор темы
export const date_theme_start = new Date('January 18, 2025 00:00:00')
export const date_theme_end = new Date('January 19, 2025 23:59:59')

// Написание текстов
export const date_texts_start = new Date('January 20, 2025 00:00:00')
export const date_texts_end = new Date('January 29, 2025 23:59:59')

// Голосование
export const date_voting_start = new Date('January 30, 2025 00:00:00')
export const date_voting_end = new Date('February 2, 2025 23:59:59')

// Результаты
export const date_results = new Date('February 3, 2025 00:00:00')
