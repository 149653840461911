import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface BetsState {
  results: {
    userName: string
    title: string
    coin: 'beecoin' | 'watermeloncoin'
    amount: number
    factor: 0 | 1 | 2 | 3
  }[]
}

const initialState: BetsState = {
  results: [],
}

export const betsSlice = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    setBetsResults: (state, action: PayloadAction<BetsState>) => {
      state.results = action.payload.results
    },
  },
})
