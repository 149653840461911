import { ExtraVote, ExtraVoteValue, ProfileState } from '../../../store/profile.slice'
import { TextBlock } from '../../texts/TextBlock'
import { ActionType, Text } from '../../../types/types'
import { nominationsList } from '../../../data/nominations'
import { fameUsers } from '../../../data/users'
import { AchievementsList } from './components/AchievementsList'
import style from './Profile.module.scss'
import { NominationsList } from './components/NominationsList'

interface Props {
  texts: Text[]
  profile: ProfileState
}

export const Profile = (props: Props) => {
  const badgesCount = {
    like: props.profile.extraVotes.filter((vote) => vote.type === 'like').length,
    theme: props.profile.extraVotes.filter((vote) => vote.type === 'theme').length,
    readable: props.profile.extraVotes.filter((vote) => vote.type === 'readable').length,
    style: props.profile.extraVotes.filter((vote) => vote.type === 'style').length,
  }

  const fameUser = fameUsers.find((fUser) => fUser.name === props.profile.realName)

  // Нужно
  // Проголосовать за все тексты
  // Поставить ВСЕ дополнительные оценки
  // Можно не ставить 3 номинации
  // Поставить в угадайке хотя бы половину текстов
  // Сделать хотя бы 2 ставки

  const textsCount = props.texts.length - 1
  const extraVotesCount = badgesCount.like + badgesCount.theme + badgesCount.readable + badgesCount.style
  const isExtraVoted = extraVotesCount === textsCount * 4
  const isNominated = props.profile.nominations.length >= nominationsList.length - 3
  const isBetted = props.profile.bets.length >= 2
  const isGuessed = props.profile.guesses.length >= textsCount / 2
  const isVoted = props.profile.votes.length === 5

  const altText = 'Альтернативный текст для сео бла бла бла зачем ты читаешь код сайта тебе что нечего делать'

  return (
    <>
      <div className={style.bio}>
        <div className={style.hint}>
          {props.profile.status === ActionType.AwaitVoting && <div>Время читать тексты и голосовать!</div>}
          {props.profile.status === ActionType.AwaitEndVoting && <div>Ждём, пока все проголосуют!</div>}
          {props.profile.status === ActionType.GameEnded && <div>Игра окончена! Спасибо за участие!</div>}
        </div>

        <div className={style.stages}>
          <table cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td colSpan={3}>
                  <img src="/img/stages/base.png" alt={altText} />
                </td>
              </tr>
              <tr>
                <td>{isExtraVoted ? <img src="/img/stages/extra_1.png" alt={altText} /> : <img src="/img/stages/extra_0.png" alt={altText} />}</td>
                <td>
                  <img src="/img/stages/text.png" alt={altText} />
                </td>
                <td>{isNominated ? <img src="/img/stages/nom_1.png" alt={altText} /> : <img src="/img/stages/nom_0.png" alt={altText} />}</td>
              </tr>
              <tr>
                <td>{isBetted ? <img src="/img/stages/bets_1.png" alt={altText} /> : <img src="/img/stages/bets_0.png" alt={altText} />}</td>
                <td>{isVoted ? <img src="/img/stages/vote_1.png" alt={altText} /> : <img src="/img/stages/vote_0.png" alt={altText} />}</td>
                <td>{isGuessed ? <img src="/img/stages/guess_1.png" alt={altText} /> : <img src="/img/stages/guess_0.png" alt={altText} />}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={style.stats}>
          <div>Голоса: {props.profile.votes.length} из 5</div>
          <div>
            Оценки: {extraVotesCount} из {textsCount * 4}
          </div>
          <div>
            Номинации: {props.profile.nominations.length} из {nominationsList.length}
          </div>
          <div>
            Угадайки: {props.profile.guesses.length} из {textsCount}
          </div>
          <div>Ставки: {props.profile.bets.length}</div>
        </div>

        {fameUser && fameUser.achievements && fameUser.achievements?.length > 0 && (
          <>
            <h2>Мои ачивки</h2>
            <AchievementsList achievements={fameUser.achievements} />
            <h2>Мои номинации:</h2>
            <NominationsList nominations={fameUser.nominations} nominationsWon={[]} />
          </>
        )}

        {props.profile.votes.length > 0 && (
          <>
            <h2>Выбранные тексты</h2>
            <div className="texts">
              {props.profile.votes.length > 0 &&
                props.profile.votes.map((vote, index) => {
                  const text = props.texts.find((text) => text.link === vote.link)
                  const textNumber = props.texts.findIndex((text) => text.link === vote.link)
                  const badge: ExtraVote = {
                    type: 'choose',
                    value: vote.value as ExtraVoteValue,
                    textIndex: 1,
                  }
                  if (text && textNumber > -1) {
                    return <TextBlock text={text} profile={props.profile} index={textNumber + 1} key={index} badges={[badge]} />
                  }
                  return null
                })}
            </div>
          </>
        )}
      </div>
    </>
  )
}
