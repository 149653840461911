import { ActionType } from '../types/types'

type StatusDecoded = {
  [key in ActionType]: string
}

export const statusDecoder: StatusDecoded = {
  [ActionType.Greetings]: 'Только пришёл к нам',
  [ActionType.AwaitName]: 'Ожидается ввод имени',
  [ActionType.AwaitText]: 'Ожидается отправка текста',
  [ActionType.AwaitStartVoting]: 'Ждёт, пока все сдадут тексты',
  [ActionType.AwaitVoting]: 'Читает тексты и выставляет оценки',
  [ActionType.AwaitEndVoting]: 'Ждёт, пока все выставят оценки',
  [ActionType.GameEnded]: 'Закончил игру и получил результаты',
  [ActionType.Banned]: 'Забанен 💀',
}

export const clanDecoder = {
  0: 'Неизвестен',
  1: 'Аскароны',
  2: 'Пушки',
  3: 'Драники',
  4: 'Бесы',
}

export const clanInfo = {
  0: '...',
  1: 'Аскароны таинственны, про них мало что известно... Аскароны тайно владеют миром и сайтом, но, скорее, конечно, сайтом, чем миром...',
  2: 'Пушки пушисты, они любят есть и спать. Дела - это не про пушков. Пушки имеют огромное очарование, ему невозможно сопротивляться...',
  3: 'Драники состоят из чистого хаоса. Драники играют с судьбами людей, они могут проникнуть в самую их суть, к ним в живот...',
  4: 'Бесы голодны и деструктивны. Всё же чуть больше голодны... Для них ты либо собрат, либо пища...',
}
